<template>
<div>
  <div class="row">
    <div class="col-sm-12">
      <p class="lead">Store Logo</p>
      <div>
      <p style="font-size: 16px; color: red;">* Please upload your images</p>
      <div style="margin-left: 10px;">
        <p style="color: red">* The photo of the ID/Document should be in high quality.</p>
        <p style="color: red;">* All four corners to be visible.</p>
        <p style="color: red">* We accept .Jpg / .PNG & PDF formats only, Must be below 3MB.</p>
        <p style="color: red">* Image Size must be '300×300' pixels.</p>
      </div>
     </div>
    </div>
  </div>
  <div class="row border">
    <div class="col-sm-12">
      <div class="row">
        <div class="col-sm-12">
            <h5><b></b></h5>
            <img  v-if="!isPdf" style="height: 300px;" slot="image" class="card-img-top" :src="contactImage1" alt="Card image cap">
            <iframe
              v-if="isPdf"
              :src="contactImage1"
              frameBorder="0"
              scrolling="none"
              height="325px"
              width="100%"
            ></iframe>
            <br>
            <br>
            <input type="file" id="embedpollfileinput" class="inputfile" @change="getFile1">
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12" style="margin-top: 30px;">
            <div class="form-group text-right" >
                <!-- <button class="btn btn-secondary merchprof-sbtn" @click="haddleBack()">Back</button>&nbsp; -->
                <button class="btn btn-primary merchprof-sbtn" @click="putFormData()" :disabled="!isProfileImageFormValid">Submit</button>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>  
</template>
<script>
import mixinStore from "@/mixins/merchant/APIStore";
import mixinProfile from "../../../mixins/merchant/APIProfile";
import { async } from 'q';
export default {
  mixins: [mixinProfile, mixinStore],
  data: function () {
    return {
        fileUploadFormData: new FormData(),
        image1: "",
        img1: "",
        isPdf: false,
        breadcrumb: [
        {
          text: "Dashboard",
          href: "/merchant-dashbord",
          active: false
        },
        {
          text: "Store List",
          href: "/merchant-store",
          active: false
        },
        {
          text: "Store Banner",
          href: "#",
          active: true
        }
      ]
    }
   },
   computed: {
    bannerId() {
      let baseurl = window.location.href.split("merchant-store-banner/");
      let id = baseurl[1];
      return id;
    },
    contactImage1: function() {
      if (this.image1) {
        return this.image1;
      } else {
        return "https://res.cloudinary.com/sri-lanka-telecom-plc/image/upload/q_auto,f_auto/v1629963258/cochchi/mercahnt/store/1629963257333.webp";
      }
    },
    isProfileImageFormValid() {
       return this.img1!=''?true: false;
    }
   },
   created() {
    // this.putFormData();
    this.handleBreadcrumb();
    this.getStoreBannerByIdCheck();
   },
   methods: {
    handleBreadcrumb: function() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb
      });
    },
    getStoreBannerByIdCheck: async function() {
      try {
        let response = await this.getStoreById(this.bannerId);
        this.image1 = response.profile_image;
        if (this.image1.split('.').pop().toLowerCase() === "pdf") {
            this.isPdf = true;
        } 
        else {
          this.isPdf = false;
        }
      } catch(error) {
        console.log(error);
      }
    },
    getFile1: async function(eventf) {
      let fileInput =  document.getElementById('embedpollfileinput');
      let filePath = fileInput.value;
      if (filePath.split('.').pop().toLowerCase() === "pdf") {
          this.isPdf = true;
      } 
      else {
        this.isPdf = false;
      }
      var tgt = eventf.target || window.event.srcElement;
      let files = tgt.files;

      let self = this;
      if (FileReader && files && files.length) {
        var fr = new FileReader();
        fr.onload = function() {
          self.image1 = fr.result;
        };
        fr.readAsDataURL(files[0]);
      }
      this.image1 = tgt.files[0];
      this.img1 = eventf.target.files[0]
    },
    putFormData: async function() {
      await this.fileUploadFormData.set("store_id", this.bannerId);
      await this.fileUploadFormData.append("image", this.img1);

     let formData = await this.fileUploadFormData;
     try {
        await this.putMerchantStoeProfileImage(formData);
        this.fileUploadFormData = new FormData();
        this.$swal.fire({
            position: 'center',
            type: 'success',
            title: 'Submit success',
            showConfirmButton: false,
            timer: 1500
        })
      this.getStoreBannerByIdCheck();
     } catch (error) {
        this.fileUploadFormData = new FormData();
        this.$swal.fire({
          position: "center",
          type: "error",
          title: "error!",
          showConfirmButton: false,
          timer: 1500,
        });
     }
    },
    haddleBack: function() {
      this.$router.push({ name: "Merchant Store" });
    }
  }
}
</script>
<style>
.border {
  border: 0.5px solid #d7d7d7 !important;
  padding: 10px;
}
.merchstorebanner .merchprof-sbtn {
  background: #5779ae;
  color: #ffffff;
  border: none;
}
.merchstorebanner .merchprof-sbtn:hover {
  color: #000000;
}
</style>

